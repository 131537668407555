<template>
  <div class='container'>
    <video ref="video" class="video" src="" controls ></video>
    <div class="detail">
      <p style="font-size: 4.2667vw;font-weight: 550;">{{vo.title}}</p>
      <div style="display: flex;align-items: center;margin-top: 4vw;  ">
        <img src="@/assets/my/teacher.png" alt="" style="width: 2.6667vw;height: 2.6667vw;margin-right: 1.3333vw;">
        <p style="font-size: 3.2vw;color: #7d7d7d;">讲师：{{vo.authorName}}</p>
      </div>
    </div>
    <div class="content">
      <div class="options">
        <div :class="['option',active===index?'hover':'']" v-for="(item,index) in options" :key="item" @click="tabChange(index)">
          {{ item }}
        </div>
      </div>
      <div class="intro" v-show="active===0">
        <p ref="intro" v-if="vo.content!=='<p><br></p>'">
        
        </p>
        <van-empty description="暂无课程详情" v-else></van-empty>
      </div>
      <div class="course" v-show="active===1">
        <div class="course-content" v-for="item in att" :key="item.id">
          <img src="@/assets/my/file.png" alt="" style="width: 5.3333vw;height: 5.3333vw;">
          <p class="omit" style="margin: 0 4.8vw;font-size: 3.7333vw;flex: 1;">{{item.name||item.title}}</p>
          <button @click="isDialog=true">下载</button>
        </div>
      </div>
    </div>
    <van-dialog v-model="isDialog" title="温馨提示" :showConfirmButton="false">
      <div class="dialog">
        <img src="@/assets/dialog-bg.png"  style="width: 40vw;height: 40vw;margin: 5.3333vw 0 4vw 0;"/>
        <p>移动端暂未开放下载功能，</p>
        <p>如需下载请在PC端进行</p>
        <button @click="isDialog=false">我知道了</button>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {learningDetail,learningStudentDetail} from '@/api/learn.js'
export default {
  data(){
    return{
      options:['课程介绍','教学资源'],
      active:0,
      isDialog:false,
      vo:{},
      att:{},
      type:null,
    }
  },
  created(){
  },
  mounted(){
    const id=this.$route.query.id*1
    const {type}=this.$route.query
    if(type){
      this.type=type*1
    }
    this.getDetail(id)
    setTimeout(()=>{
      /* console.log(this.$refs.intro.innerHTML);
      console.log(this.vo.content); */
      this.$refs.intro.innerHTML=this.vo.content
    },1000)
  },
  methods:{
    async getDetail(id){
      let res 
      if(this.type===1){
        res = await learningStudentDetail({id})
      }else{
        res = await learningDetail({id})
      }
      this.vo = res.data.vo
      this.att = res.data.att
      this.$refs.video.src=this.vo.fileLearning
    },
    tabChange(active){
      this.active=active
    }
  }
}
</script>
<style lang='less' scoped>
.container{
  min-height: 100%;
  background-color: rgba(242, 242, 242, 1);
  .video{
    width: 100%;
    height: 58.4vw;
  }
  .detail{
    padding: 4.8vw;
    background-color: #fff;
  }
  .content{
    margin-top: 4vw;
    min-height: 99.7333vw;
    background-color: #fff;
    .options{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      .option{
        padding: 4.8vw 0;
        position: relative;
        color: rgb(110, 109, 109);
        font-size: 4.2667vw;
        font-weight: 550;
      }
      .option.hover{
        color: #333;
        &::before{
          content: '';
          position: absolute;
          bottom: 15%;
          left: 50%;
          width:2.6667vw;
          height: .5333vw;
          background-color: #1DD792;
          border-radius: .8vw;
          transform: translateX(-50%);
        }
      }
    }
    .intro{
      padding: 4vw;
      color: rgba(128, 128, 128, 1);
      font-size: 3.2vw;
      line-height: 5.3333vw;
    }
    .course{
      padding: 4vw;
      font-size: 3.7333vw;
      .course-content{
        margin-bottom: 6.6667vw;
        display: flex;
        align-items: center;
        button{
          padding: 1.3333vw 2.6667vw;
          height: 7.4667vw;
          border-radius: 13.3333vw;
          color: #fff;
          background-color: #67ADFF;
        }
      }
    }
  }
  .dialog{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.4667vw;
    p{
      line-height: 6.4vw;
    }
    button{
      margin: 8vw;
      width: 45.3333vw;
      height: 11.2vw;
      color: #fff;
      line-height: 11.2vw;
      text-align: center;
      border-radius: 13.3333vw;
      background-color: #5EDBC9;
    }
  }
}
</style>