import {request} from '@/utils/request'
// 课程分类
export function learningCats (params) {
    return request({
        url: '/orgLearning/cats',
        method: 'get',
        params

    })
}
// 课程列表
export function learningList (params) {
    return request({
        url: '/orgLearning/list',
        method: 'get',
        params
    })
}
// 课程详情
export function learningDetail (params) {
    return request({
        url: '/orgLearning/detail',
        method: 'get',
        params
    })
}
// 学生心理课程列表
export function learningStudentList (params) {
  return request({
      url: '/orgLearningStudent/list',
      method: 'get',
      params

  })
}
// 学生心理课堂详情
export function learningStudentDetail (params) {
  return request({
      url: '/orgLearningStudent/detail',
      method: 'get',
      params

  })
}
// 学生心理课堂获取学校层级
export function getSchoolLevel (params) {
  return request({
      url: '/orgLearningStudent/getSchoolLevel',
      method: 'get',
      params

  })
}
// 学生心理课堂获取年级
export function getGrades (params) {
  return request({
      url: '/orgLearningStudent/getGrades',
      method: 'get',
      params

  })
}
// 直播列表
export function courses () {
  return request({
      url: '/orgCourse/list',
      method: 'get'
  })
}